//Core
import React from "react"

//Styles
import style from "./style.module.scss"

const AppLink = ({ img, link }) => {
  return (
    <a
      href={link}
      className={style.AppLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={img} alt="TAS application for agents"></img>
    </a>
  )
}

export default AppLink
