//Core
import React from "react"

//Styles
import style from "./style.module.scss"
import Fade from "react-reveal/Fade"

//Components
import Program from "./Program"

//Images
import car1 from "../../images/car1.jpg"
import car2 from "../../images/car2.jpg"

const agentProgram = [
  {
    img: car1,
    title: "Програма  «Авто Драйв»",
    list: [
      `  Сума кредиту до 1 млн грн `,
      `  Строк кредиту до 84 міс.`,
      `  Передплата від 20% вартості авто`,
      `  Ставка по кредиту 11,99% річних (фіксована)`,
      `  Одноразова комісія за надання кредиту відсутня`,
      `  Щомісячна комісія 0,19% від початкової суми кредиту`,
      `  Страхування Життя Позичальника від нещасного випадку - відсутнє`,
      `  Страхування КАСКО – від 5,6% від вартості автомобіля`,
      `  В разі оформлення кредиту в сумі 1 000 000,00 грн. на строк 84 міс. реальна річна процентна ставка за кредитом, з урахуванням вартості послуг третіх осіб, становитиме 29,43% при купівлі б/в авто та 33,50% при купівлі нового авто.`,
      `  Вік б/в автомобіля на момент погашення кредиту повинен становити не більше 15 років для авто іноземного виробництва, 7 років для авто вітчизняного виробництва , виробництва країн СНД, Ірану, Індії, Китаю.`
    ],
  },
  {
    img: car2,
    title: "Програма «Автолайт»",
    list: [
      `Сума кредиту до 500 тис. грн`,
      `Строк кредиту до 60 міс.`,
      `Передплата 25% від вартості авто`,
      `Ставка за кредитом 0,001% річних (фіксована)`,
      `Одноразова комісія за надання кредиту відсутня`,
      `Страхування Життя Позичальника від нещасного випадку - відсутнє`,
      `Страхування КАСКО – від 5,6% від вартості автомобіля`,
      `Нотаріальне оформлення договору застави відсутнє при сумі кредиту до 150 тис. грн.`,
      `В разі оформлення кредиту в сумі 500 000,00 грн. на строк 60 міс. із сплатою щомісячної комісії 1% реальна річна процентна ставка за кредитом, з урахуванням вартості послуг третіх осіб, становитиме 36,62%.`,
      `Вік б/в автомобіля на момент погашення кредиту повинен становити не більше 15 років для авто іноземного виробництва, 7 років для авто вітчизняного виробництва , виробництва країн СНД,  Ірану, Індії, Китаю.`
    ],
    reverse: true,
    nestedList: {
      title: `Щомісячна комісія:`,
      list: [
        ` - 2,00% від початкової суми кредиту за перший місяць кредитування та до моменту укладення договору застави та страхування авто`,
        ` - 1,00% від суми кредиту після укладення з Банком договору застави та страхування КАСКО`
      ],
    },
  },
]

const Programs = () => {
  return (
    <main className={style.ProgramsWrapper}>
      <section className={style.Programs}>
        {agentProgram.map((item, key) => (
          <Fade right left={(key + 1) % 2}>
            <Program {...item} key={key}></Program>
          </Fade>
        ))}
      </section>
    </main>
  )
}

export default Programs
