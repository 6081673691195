//Core
import React from "react"

//Style
import style from "./style.module.scss"
import Bounce from "react-reveal/Bounce"

const service = [
  `●  Кредитуємо  купівлю автомобілів  будь-яких  марок, моделей,  без обмеження  пробігу`,
  `●  Включаємо в тіло кредиту платежі за договорами страхування`,
  `●  Розглядаємо клієнтів з будь-якою кредитною історією та різним рівнем доходу і приймаємо рішення протягом 2 годин`,
  `●  За необхідності, оформлення автомобіля можливе на третю особу`,
]

const Advanteg = () => {
  return (
    <div className={style.AdvantegWrapper}>
      <div className={style.Advanteg}>
        <Bounce top>
          <h2 className={style.title}>
            З нами ви легко і швидко продасте авто:
          </h2>
          <ul className={style.list}>
            {service.map((text, key) => (
              <li key={key}>{text}</li>
            ))}
          </ul>
        </Bounce>
      </div>
    </div>
  )
}

export default Advanteg
