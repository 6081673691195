//Core
import React from "react"

//Styles
import style from "./style.module.scss"

const StepElement = ({ img, text, center }) => {
  return (
    <div
      className={
        center ? [style.StepElement, style.center].join(" ") : style.StepElement
      }
    >
      <div className={style.ImageWrapper}>
        <img src={img} alt="Як стати агентом?| ТАСКОМБАНК"></img>
        <p className={style.text}>{text}</p>
      </div>
    </div>
  )
}

export default StepElement
