//Core
import React from "react"

//Styles
import style from "./style.module.scss"
import Bounce from "react-reveal/Bounce"

//Components
import ContactLink from "./ContactLink"
import AppLink from "./AppLink"

//Images
import google_pay from "../../images/google_pay.jpg"
import apple_pay from "../../images/apple_pay.jpg"

const links = [
  { text: `+380 (98) 875 8300`, link: "tel:380988758300" },
  { text: `+380 (93) 875 8300`, link: "tel:380938758300" },
  { text: `+380 (95) 875 8300`, link: "tel:380958758300" },
  { text: `agent.tascombank.ua`, link: "https://agent.tascombank.ua/" },
]

const apps = [
  {
    img: google_pay,
    link:
      "https://play.google.com/store/apps/details?id=ua.tascombank.tasagent",
  },
  {
    img: apple_pay,
    link:
      "https://apps.apple.com/ua/app/%D1%82%D0%B0%D1%81-%D0%B0%D0%B3%D0%B5%D0%BD%D1%82/id1457664939",
  },
]

const Contacts = () => {
  return (
    <div className={style.ContactLinkWrapper}>
      <div className={style.ContactLink}>
        <Bounce left>
          <div>
            <div className={style.ContactsNumber}>
              <h4 className={style.title}>ДІЗНАЙСЯ БІЛЬШЕ</h4>
              {links.map((item, key) => (
                <ContactLink {...item} key={key}></ContactLink>
              ))}
            </div>
          </div>
        </Bounce>
        <Bounce right>
          <div className={style.Apps}>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={style.button}
                href="https://agent.tascombank.ua/login/one"
              >
                Реєструйтесь
              </a>
              <div className={style.subtitle}>Або завантажуйте</div>
              <div className={style.AppWrapper}>
                {apps.map((item, key) => (
                  <AppLink {...item} key={key} />
                ))}
              </div>
            </div>
          </div>
        </Bounce>
      </div>
    </div>
  )
}

export default Contacts
