//Core
import React from "react"

//Styles
import style from "./style.module.scss"

const ContactLink = ({ link, text }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={style.ContactLink}
    >
      {text}
    </a>
  )
}

export default ContactLink
