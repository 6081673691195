//Core
import React from "react"

//Style
import style from "./style.module.scss"

//Images
import logo from "../../images/logo.png"
import main from "../../images/main.jpg"
import arrow from "../../images/line-white.png"

//helpers
console.log(
  `%c TASCOMBANK`,
  "background: #333366; color: white; font-size: 15px; padding: 3px 40%;"
)

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.menu}>
        <a
          href="https://tascombank.ua/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="Таскомбанк" className={style.logo} />
        </a>
      </div>
      <img src={main} alt="Таскомбанк Тас Агент" className={style.Image} />
      <div className={style.Content}>
          <h1 className={style.mainText}>
              Cтаньте агентом та отримуйте 2000 грн (з ПДВ) від кожного кредиту за програмами «Автодрайв» та «Автолайт»!
          </h1>

          <a
              className={style.button}
              target="_blank"
              rel="noopener noreferrer"
              href="https://agent.tascombank.ua/login/one"
          >
              Стати агентом
          </a>
      </div>
      <div className={style.mainImage}></div>
      <div className={style.arrowWrapper}>
        <span>Детальніше</span>
        <img src={arrow} className={style.arrow} alt="arrow icon"></img>
      </div>
    </header>
  )
}

export default Header
