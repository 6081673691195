//Core
import React from "react"

//Styles
import style from "./style.module.scss"
import Flip from "react-reveal/Flip"

//Components
import StepElement from "./StepElement"

//Images
import data from "../../images/data.svg"
import domain from "../../images/domain.svg"
import dataServer from "../../images/data-server.svg"

const steps = [
  {
    img: data,
    text: "Зареєструйтесь на сайті або в мобільному додатку ТАС Агент",
  },
  {
    img: domain,
    text: `Знайдіть клієнта та подайте заявку`,
    center: true,
  },
  {
    img: dataServer,
    text: `Отримайте 2000 грн (з ПДВ) від кожного виданого кредиту`,
  },
]

const StepByStep = () => {
  return (
    <div className={style.StepByStepContainer}>
      <div className={style.StepByStepWrapper}>
        <div className={style.StepByStep}>
          {steps.map((item, key) => (
            <Flip top>
              <StepElement {...item} key={key}></StepElement>
            </Flip>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepByStep
