//Core
import React from "react"

//GlobalStyles
import ".././theme/main.scss"

//Components
import SEO from "../components/seo"
import Header from "../components/Header"
import StepByStep from "../components/StepByStep"
import Advanteg from "../components/Advanteg"
import Programs from "../components/Programs"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"

const IndexPage = () => (
  <>
    <SEO
      title="Продаєш авто 🚗? Продай швидше і зароби більше!💸"
      description={`Продавай авто в кредит,🚙 та отримуй 2000 грн (з ПДВ) від кожного кредиту за програмами «Авто драйв та Авто лайт» 💰`}
    />
    <Header></Header>
    <StepByStep></StepByStep>
    <Advanteg></Advanteg>
    <Programs></Programs>
    <Contacts></Contacts>
    <Footer></Footer>
  </>
)

export default IndexPage
