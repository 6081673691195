//Core
import React from "react"

//Styles
import style from "./style.module.scss"

const Program = ({ img, list = [], title, reverse, nestedList }) => {
  return (
    <section className={style.ProgramWrapper}>
      <div
        className={style.Program}
        style={
          reverse ? { flexDirection: "row-reverse", paddingBottom: "10px" } : {}
        }
      >
        <div className={style.imageWrapper}>  
          <img
            src={img}
            className={style.image}
            alt="ТАСКОМБАНК програми для агентів"
          ></img>
        </div>
        <div className={style.ListWrapper}>
          <h3 className={style.title}>{title}</h3>
          <ul className={style.list}>
            {list.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
            {nestedList ? (
              <>
                <li>{nestedList.title}</li>
                <ul>
                  {nestedList.list.map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </>
            ) : null}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Program
